import React, {createContext, useState, useEffect} from 'react';
import apiClient from "../utils/axiosConfig";




const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const GatewayDataContext = createContext();

export const GatewayDataProvider = ({children}) => {
    const [data, setData] = useState({
        currentlyOnline: 0,
        pastHour: 0,
        pastTwelveHours: 0,
        pastDay: 0,
        pastWeek: 0,
        totalGateway: 0,
    });

    const [isAuthenticated, setIsAuthenticated] = useState(
      () => localStorage.getItem("isAuthenticated") === "true"
    );
    
    const fetchData = async (interval) => {
        try{
            const response = await apiClient.get(`${apiBaseUrl}/analytics/getactivegateway?interval=${interval}`); 

            if(interval === 2){
                setData((prevState) => ({
                    ...prevState,
                    currentlyOnline: response.data.activeGateway,
                    totalGateway: response.data.totalGateway, 
                }))
            } else if (interval === 60){
                setData((prevState) => ({
                    ...prevState,
                    pastHour: response.data.activeGatewayPastHour,
                    totalGateway: response.data.totalGateway, 
                }))
            } else if (interval === 720){
                setData((prevState) => ({
                    ...prevState,
                    pastTwelveHours: response.data.activeGatewayPastTwelveHours,
                    totalGateway: response.data.totalGateway, 
                }))
            } else if (interval === 1440){
                setData((prevState) => ({
                    ...prevState,
                    pastDay: response.data.activeGatewayPastDay,
                    totalGateway: response.data.totalGateway, 
                }))
            } else if (interval === 10080){
                setData((prevState) => ({
                    ...prevState,
                    pastWeek: response.data.activeGatewayPastWeek,
                    totalGateway: response.data.totalGateway, 
                }))
            }
        } catch (error) {
            console.error(`Error fetching data : ${error}`);
        }
    }

    useEffect(() => {
      if(isAuthenticated){
        setIsAuthenticated(true);
        fetchData(2); 
        fetchData(60); 
        fetchData(720); 
        fetchData(1440); 
        fetchData(10080); 
        const interval2 = setInterval(() => {
          fetchData(2);
        }, 60000); 
    
        const interval60 = setInterval(() => {
          fetchData(60);
        }, 3600000); 
    
        const interval720 = setInterval(() => {
          fetchData(720);
        }, 43200000); 
    
        const interval1440 = setInterval(() => {
          fetchData(1440);
        }, 86400000); 
    
        const interval10080 = setInterval(() => {
          fetchData(10080);
        }, 604800000); 
    
        return () => {
          clearInterval(interval2);
          clearInterval(interval60);
          clearInterval(interval720);
          clearInterval(interval1440);
          clearInterval(interval10080);
        };
      }
        
      }, [isAuthenticated]);

      const authenticate = (authStatus) => {
        setIsAuthenticated(authStatus);
        localStorage.setItem("isAuthenticated", authStatus); // Persist the auth status in localStorage
      };
      return (
        <GatewayDataContext.Provider value={{ data, fetchData, setIsAuthenticated : authenticate }}>
          {children}
        </GatewayDataContext.Provider>
      );
}

