import React, { useState } from "react";
import Sidebar from "../sidebar/sidebar";
import UserManagementContent from "./user_content";

function UserManagementPage(){
  return (
    <>
      <div className="flex min-h-screen">
        {/* Sidebar */}

        <div className="w-64 bg-gray-100 flex flex-column">
          <Sidebar />
        </div>

        {/* Content (Table) */}
        <div className="flex-grow p-5">
          <h1 className="text-2xl font-bold text-blue-600 mb-4">
            User Management
          </h1>
          <UserManagementContent/>
        </div>
      </div>
    </>
  );
}

export default UserManagementPage;