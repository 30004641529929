import React, { useState } from "react";
import Sidebar from "../sidebar/sidebar";
import SettingsContent from "./settingsContent";

function Settings(){

    return(
        <>
      <div className="flex min-h-screen">
        {/* Sidebar */}

        <div className="w-64 bg-gray-100 flex flex-column">
          <Sidebar />
        </div>

        {/* Content (Table) */}
        <div className="flex-grow p-5">
          <h1 className="text-2xl font-bold text-blue-600 mb-4">
            Settings
          </h1>
          <SettingsContent/>
        </div>
      </div>
    </>
    )
}

export default Settings;