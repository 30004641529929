import React, { useState } from "react";
import Sidebar from "../sidebar/sidebar";

function Dashboard(){

    return(
        <>
      <div className="flex min-h-screen">
        {/* Sidebar */}

        <div className="w-64 bg-gray-100 flex flex-column">
          <Sidebar />
        </div>

        {/* Content (Table) */}
        <div className="flex-grow p-5">
          <h1 className="text-2xl font-bold text-blue-600 mb-4">
            Dashboard
          </h1>
        </div>
      </div>
    </>
    )
}

export default Dashboard;