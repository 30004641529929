import { useState, useEffect } from "react";
import apiClient from "../utils/axiosConfig";
import Sidebar from "../sidebar/sidebar";
import { useLocation } from "react-router-dom";
function GatewayConnectivityLogsPage() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [logsData, setLogsData] = useState([]);
  const [gatewayData, setGatewayData] = useState();
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const { serialNumber } = location.state || {};
  const [totalPage, setTotalPage] = useState();
  const [totalCount, setTotalCount] = useState();
  const [isTransitioning, setIsTransitioning] = useState(false);
  useEffect(() => {
    fetchLogs(serialNumber, 1);
  }, [serialNumber]);
  const handlePageChange = (pageNumber) => {
    if(pageNumber == currentPage) {
        return;
    }
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(pageNumber); // Move to the selected page
      setIsTransitioning(false);
    }, 300);

    fetchLogs(serialNumber, pageNumber);
  };
  const renderPaginationButtons = () => {
    const pageButtons = [];
    const totalVisiblePages = 5; // Number of pages to show around the current page
    const startPage = Math.max(
      1,
      currentPage - Math.floor(totalVisiblePages / 2)
    );
    const endPage = Math.min(
      totalPage,
      currentPage + Math.floor(totalVisiblePages / 2)
    );

    // Always show the first page
    if (startPage > 1) {
      pageButtons.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === 1 ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pageButtons.push(
          <span key="start-ellipsis" className="mx-1 px-3 py-1">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === i ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPage) {
      if (endPage < totalPage - 1) {
        pageButtons.push(
          <span key="end-ellipsis" className="mx-1 px-3 py-1">
            ...
          </span>
        );
      }
      pageButtons.push(
        <button
          key={totalPage}
          onClick={() => handlePageChange(totalPage)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === totalPage ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          {totalPage}
        </button>
      );
    }

    return pageButtons;
  };

  const fetchLogs = async (serialNumber, currentPage) => {
    try {
      const result = await apiClient.get(
        `${apiBaseUrl}/analytics/connectionhistory?pageNumber=${currentPage}&pageSize=${pageSize}&gatewaySN=${serialNumber}`
      );
      setLogsData(result.data.gatewayActivityDtos);
      setTotalPage(result.data.totalPage);
      setCurrentPage(result.data.pageNumber);
      setTotalCount(result.data.totalCount);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="flex h-auto">
      <div className="flex-grow">
        <h1 className="font-bold mb-5">
          Gateway Connectivity Logs for {serialNumber}
        </h1>
        <table
          className={`table-auto w-full border-collapse border border-gray-600 transition-opacity duration-300 ${
            isTransitioning ? "opacity-0" : "opacity-100"
          }`}
        >
          <thead>
            <tr className="bg-gray-500">
              <th className="border p-2 text-white">Index</th>
              <th className="border p-2 text-white">Status</th>
              <th className="border p-2 text-white">Event Date Time</th>
            </tr>
          </thead>
          <tbody>
            {logsData.map((row, index) => {
              return (
                <tr key={index} className={row.statusValue === 5 ? 'bg-green-200' : 'bg-red-200'}>
                  <td className="border p-2 text-black">
                    {index + 1 + (currentPage - 1) * pageSize}
                  </td>
                  <td className="border p-2 text-black">
                    {row.statusValue === 5 ? "Connected" : "Disconnected"}
                  </td>
                  <td className="border p-2 text-black">
                    {" "}
                    {new Date(row.eventDateTime).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
        {renderPaginationButtons()}
      </div>
      </div>
    </div>
  );
}

export default GatewayConnectivityLogsPage;
