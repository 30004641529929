import React, { useEffect, useState } from "react";
import apiClient from "../utils/axiosConfig";

function TrialTab({records, currentPage, pageSize}){
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const handleRowSelect = async (id) => {
    const newSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];
      
    setSelectedRows(newSelectedRows);
    await fetchTotalPayment(newSelectedRows);
  };
  
  const handlePaymentButtonClick = async (selectedIds) => {

  }
  
  const fetchTotalPayment = async (rcIds) => {
    if (rcIds.length === 0) {
      setTotalPayment(0);
      return;
    }
    try {
      const rcIdParams = rcIds.map(id => `rcId=${id}`).join('&');
      const response = await apiClient.get(`${apiBaseUrl}/cms/paymentAmount?${rcIdParams}`);
      setTotalPayment(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };
  
    return (
      <div className="table-container">
        <table className="w-full border border-gray-300">
          <thead>
            <tr>
              <th>Select</th>
              <th>Index</th>
              <th>Gateway SN</th>
              <th>Trial Start</th>
              <th>Trial End</th>
              <th>Payment Amount (RM)</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record, index) => {
              return (
                <tr id={record.id}>
                  <td className="border p-2">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(record.id)}
                      onChange={() => handleRowSelect(record.id)}
                    />
                  </td>
                  <td className="border p-2">
                    {index + 1 + (currentPage - 1) * pageSize}
                  </td>
                  <td className="border p-2">
                    {record.gatewaySN}
                  </td>
                  <td className="border p-2">
                  {new Date(record.subscriptionStart).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                        .toUpperCase()}
                  </td>
                  <td className="border p-2">
                  {new Date(record.subscriptionEnd).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                        .toUpperCase()}
                  </td>
                  <td className="border p-2">
                        {record.amountDue}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="w-full">
            <p className="ml-auto">Subtotal: RM{totalPayment}</p>
        </div>
        <div className="w-full flex">
        <button onClick={handlePaymentButtonClick(selectedRows)} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded ml-auto w-32">
          Pay
        </button>
        </div>
        
      </div>
    );
}

export default TrialTab; 