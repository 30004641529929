import { useState, useRef, useEffect } from "react";
import apiClient from "../utils/axiosConfig";
import { FaEdit, FaTrash, FaArrowLeft, FaSave, FaTimes } from "react-icons/fa";
import { Tabs, Tab, Box } from "@mui/material";

function UploadImageContent() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchCache, setSearchCache] = useState([]);
  const [data, setData] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [currentGatewayIndex, setCurrentGatewayIndex] = useState(0);
  const searchTimeoutRef = useRef();
  const [editedData, setEditedData] = useState();
  const [isDiscard, setIsDiscard] = useState(true);
  const [isEdit, setIsEdit] = useState();
  const [isDetailsChanged, setIsDetailsChanged] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);
  

  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    if (
      event.target.value === null ||
      event.target.value === undefined ||
      event.target.value === ""
    ) {
      setSearchData([]);
      setSearchCache([]);
      return;
    }
    try {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      searchTimeoutRef.current = setTimeout(() => {
        fetchSearchResult(event.target.value);
      }, 500);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (JSON.stringify(editedData) !== JSON.stringify(data)) {
      setIsDetailsChanged(true);
    } else {
      setIsDetailsChanged(false);
    }
  }, [editedData, data]);

  const convertImageToBinary = async (imageurl) => {
    const response = await fetch(imageurl);
    const blob = await response.blob();
    const file = new File([blob], `${imageurl}.jpg`, {type: blob.type});
    return file;
  };
  const saveData = async () => {
    try {
      const formData = new FormData();

      if (editedData.policeImage !== data.policeImage) {
        var policeUrl = await convertImageToBinary(editedData.policeImage ?? data.policeImage);
        formData.append("PoliceImage", policeUrl);
      }
      if (editedData.bombaImage !== data.bombaImage) {
        var bombaUrl = await convertImageToBinary(editedData.bombaImage ?? data.bombaImage);
        formData.append("BombaImage", bombaUrl);
      }
      if (editedData.medicalImage !== data.medicalImage) {
        var medicalUrl = await convertImageToBinary(editedData.medicalImage ?? data.medicalImage);
        formData.append("MedicalImage", medicalUrl);
      }

      formData.append("GatewaySN", data.gatewaySN);
      formData.append("PoliceContact", editedData.policeContact);
      formData.append("BombaContact", editedData.bombaContact);
      formData.append("MedicalContact", editedData.medicalContact);


      const result = await apiClient.post(
        `${apiBaseUrl}/GatewayImage/uploadimage`,
        formData
      );

      if (result) {
        await fetchData(data.gatewaySN);
        alert("Gateway images updated successfully!");
        setIsEdit(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditedData({
      ...editedData,
      [name]: value,
    });
  };

  const handleBlur = () => {
    setTimeout(() => setSearchData([]), 100);
  };

  const handleFocus = () => {
    if (searchCache.length > 0) {
      setSearchData(searchCache);
    } else {
      setSearchData([]);
    }
  };
  const fetchSearchResult = async (searchTerm) => {
    try {
      const result = await apiClient.get(
        `${apiBaseUrl}/Whitelist/search_gateway?searchTerm=${searchTerm}`
      );
      if (result) {
        setSearchData(result.data.gateways);
        setSearchCache(result.data.gateways);
      }
      console.log(result);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchData = async (gatewaySN) => {
    const result = await apiClient.get(
      `${apiBaseUrl}/GatewayImage/getimage?gatewaySN=${gatewaySN}`
    );
    if (result) {
      const policeImage = result.data.policeImage 
      ? await fetchImage(result.data.policeImage.imagePath)
      : null;
    
    const bombaImage = result.data.bombaImage 
      ? await fetchImage(result.data.bombaImage.imagePath)
      : null;
    
    const medicalImage = result.data.medicalImage 
      ? await fetchImage(result.data.medicalImage.imagePath)
      : null;
      setData({
          gatewaySN: result.data.gatewaySN || "", 
          policeImage: policeImage || null, 
          bombaImage: bombaImage || null, 
          medicalImage: medicalImage || null, 
          policeContact: result.data.policeContact || "", 
          bombaContact: result.data.bombaContact || "",  
          medicalContact: result.data.medicalContact || "" 
        });
        setEditedData({
          gatewaySN: result.data.gatewaySN || "", 
          policeImage: policeImage || null, 
          bombaImage: bombaImage || null, 
          medicalImage: medicalImage || null, 
          policeContact: result.data.policeContact || "", 
          bombaContact: result.data.bombaContact || "",  
          medicalContact: result.data.medicalContact || "" 
        });
    }
    console.log(result);
  }
  const handleResultClick = async (gatewaySN) => {
    if(gatewaySN === data?.gatewaySN ?? ""){
      return;
    }
    if(isEdit && isDetailsChanged){
      if(window.confirm("Discard edit?")){
        setIsEdit(false);
      }
      else{
        setIsEdit(true);
        return; 
      }
    } else {
      setIsEdit(false);
    }
    try {
      fetchData(gatewaySN);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchImage = async (imagePath) => {
    const response = await apiClient.get(
      `${apiBaseUrl}/GatewayImage/getimagefile/${imagePath}`,
      { responseType: "blob" } 
    );
    return URL.createObjectURL(response.data); 
  };
  const discardDetailsEdit = () => {
    setEditedData(data);
  };

  return (
    <div className="h-auto w-auto bg-blue-100 p-4 pb-8">
      <div className="mb-4 mr-4 ml-4 mt-4 relative">
        <input
          type="text"
          placeholder="Search for gateway's serial number..."
          value={searchTerm}
          onChange={handleSearch}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className="border p-2 w-full border-blue-500 rounded-lg relative"
        />
        {searchData.length > 0 && (
          <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-10 max-h-48 overflow-y-auto">
            {searchData.map((result, index) => (
              <div
                key={index}
                className="p-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => handleResultClick(result.gatewaySN)}
              >
                {result.gatewaySN}
              </div>
            ))}
          </div>
        )}
        <div className="mt-6">
          {data && (
            <h1 className="font-bold">Image data for {data.gatewaySN}</h1>
          )}
        </div>

        <div className="flex flex-column mt-6">
          {data && (
            <GatewayTabs
              data={data}
              handleInputChange={handleInputChange}
              editedData={editedData}
              discardDetailsEdit={discardDetailsEdit}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setEditedData={setEditedData}
              saveData={saveData}
            />
          )}
        </div>
      </div>
    </div>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="p-4"
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const GatewayTabs = ({ data, handleInputChange, editedData, discardDetailsEdit, isEdit, setIsEdit, setEditedData, saveData }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const handleTabChange = (event, newIndex) => {
     setTabIndex(newIndex);
  };
  
  const handleEditClick = () => {
    setIsEdit(true);
    console.log('Edit button clicked');
  };
  const handleCancelClick = () => {
    discardDetailsEdit();
    setIsEdit(false);
  }

  const handleSaveClick = () => {
    saveData();
  }

  const handleFileUpload = (event, imageName) => {
    const file = event.target.files[0];

    if(file) {
      const imageUrl = URL.createObjectURL(file);
      setEditedData((prevData) => ({
        ...prevData,
        [imageName]: imageUrl
      }));
    }

    event.target.value = null;
  };

  return (
    <div className="w-full">
      <div className="flex justify-center mb-4">
        {/* Tabs */}
        <button
          className={`px-4 py-2 font-semibold rounded-t-lg ${
            tabIndex === 0
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-700 hover:bg-blue-300"
          }`}
          onClick={() => handleTabChange(null, 0)}
        >
          Police
        </button>
        <button
          className={`px-4 py-2 font-semibold rounded-t-lg mx-2 ${
            tabIndex === 1
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-700 hover:bg-blue-300"
          }`}
          onClick={() => handleTabChange(null, 1)}
        >
          Bomba
        </button>
        <button
          className={`px-4 py-2 font-semibold rounded-t-lg ${
            tabIndex === 2
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-700 hover:bg-blue-300"
          }`}
          onClick={() => handleTabChange(null, 2)}
        >
          Medical
        </button>
      </div>

      {/* Tab Panels */}
      <TabPanel value={tabIndex} index={0}>
        <div className="flex flex-col items-center">
          {(!isEdit ? data.policeImage : editedData.policeImage) ? (
            <div
              className="relative group"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={!isEdit ? data.policeImage : editedData.policeImage}
                alt="Police"
                className="rounded-lg shadow-md max-w-md h-auto mb-4"
              />
              {isHovered && (
                <button
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity"
                  onClick={() => isEdit ? document.getElementById("fileInput").click() : null}
                >
                  Upload
                </button>
              )}
              <input
                id="fileInput"
                type="file"
                className="hidden"
                onChange={(event) => handleFileUpload(event, "policeImage")}
              />
            </div>
          ) : (
            <div className="flex flex-row items-center mb-4">
              <p className="text-gray-500 mr-4">No police image available</p>
              {isEdit && (
                <button
                  onClick={() => document.getElementById("fileInput").click()}
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition"
                >
                  Upload
                </button>
              )}
              <input
                id="fileInput"
                type="file"
                className="hidden"
                onChange={(event) => handleFileUpload(event, "policeImage")}
              />
            </div>
          )}
          <div className="flex flex-row items-center">
            <p className="text-gray-600 mr-2">Contact:</p>
            {isEdit ? (
              <input
                type="text"
                name="policeContact"
                value={editedData.policeContact}
                onChange={handleInputChange}
                className="border p-2 w-auto"
              ></input>
            ) : (
              <p className="text-gray-600">
                {data.policeContact ?? "No contact available"}
              </p>
            )}
          </div>
        </div>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <div className="flex flex-col items-center">
          {(!isEdit ? data.bombaImage : editedData.bombaImage) ? (
            <div
              className="relative group"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={!isEdit ? data.bombaImage : editedData.bombaImage}
                alt="Police"
                className="rounded-lg shadow-md max-w-md h-auto mb-4"
              />
              {isHovered && (
                <button
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity"
                  onClick={() => isEdit ? document.getElementById("fileInput").click() : null}
                >
                  Upload
                </button>
              )}
              <input
                id="fileInput"
                type="file"
                className="hidden"
                onChange={(event) => handleFileUpload(event, "bombaImage")}
              />
            </div>
          ) : (
            <div className="flex flex-row items-center mb-4">
              <p className="text-gray-500 mr-4">No bomba image available</p>
              {isEdit && (
                <button
                  onClick={() => document.getElementById("fileInput").click()}
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition"
                >
                  Upload
                </button>
              )}
              <input
                id="fileInput"
                type="file"
                className="hidden"
                onChange={(event) => handleFileUpload(event, "bombaImage")}
              />
            </div>
          )}
          <div className="flex flex-row items-center">
            <p className="text-gray-600 mr-2">Contact:</p>
            {isEdit ? (
              <input
                type="text"
                name="bombaContact"
                value={editedData.bombaContact}
                onChange={handleInputChange}
                className="border p-2 w-auto"
              ></input>
            ) : (
              <p className="text-gray-600">
                {data.bombaContact ?? "No contact available"}
              </p>
            )}
          </div>
        </div>
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <div className="flex flex-col items-center">
          {(!isEdit ? data.medicalImage : editedData.medicalImage) ? (
            <div
              className="relative group"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={!isEdit ? data.medicalImage : (editedData.medicalImage ?? data.medicalImage)}
                alt="Police"
                className="rounded-lg shadow-md max-w-md h-auto mb-4"
              />
              {isHovered && (
                <button
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity"
                  onClick={() => isEdit ? document.getElementById("fileInput").click() : null}
                >
                  Upload
                </button>
              )}
              <input
                id="fileInput"
                type="file"
                className="hidden"
                onChange={(event) => handleFileUpload(event, "medicalImage")}
              />
            </div>
          ) : (
            <div className="flex flex-row items-center mb-4">
              <p className="text-gray-500 mr-4">No medical image available</p>
              {isEdit && (
                <button
                  onClick={() => document.getElementById("fileInput").click()}
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition"
                >
                  Upload
                </button>
              )}
              <input
                id="fileInput"
                type="file"
                className="hidden"
                onChange={(event) => handleFileUpload(event, "medicalImage")}
              />
            </div>
          )}
          <div className="flex flex-row items-center">
            <p className="text-gray-600 mr-2">Contact:</p>
            {isEdit ? (
              <input
                type="text"
                name="medicalContact"
                value={editedData.medicalContact}
                onChange={handleInputChange}
                className="border p-2 w-auto"
              ></input>
            ) : (
              <p className="text-gray-600">
                {data.medicalContact ?? "No contact available"}
              </p>
            )}
          </div>
        </div>
      </TabPanel>
      {!isEdit ? (
        <div className="flex justify-center mt-6">
          <button
            onClick={handleEditClick}
            className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition"
          >
            Edit
          </button>
        </div>
      ) : (
        <div className="flex flex-row justify-center mt-6">
          <button
            onClick={handleSaveClick}
            className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition mr-6"
          >
            Save
          </button>
          <button
            onClick={handleCancelClick}
            className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};


export default UploadImageContent;
