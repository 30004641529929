import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../sidebar/sidebar";
import WarrantyContent from "./warranty_content";
import apiClient from "../utils/axiosConfig";

function Warranty() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchCache, setSearchCache] = useState([]);
  const [deviceData, setDeviceData] = useState();
  const [selectedType, setSelectedType] = useState(0);
  const searchTimeoutRef = useRef(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    if(searchTerm === null || searchTerm === undefined || searchTerm === ""){
      setSearchResult([]);
      setSearchCache([]);
      return; 
    }
    try {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      searchTimeoutRef.current = setTimeout(() => {
        fetchSearchResult(searchTerm);
      }, 500);
    } catch (err) {
      console.error(err);
    }
  }, [searchTerm]);

  async function fetchSearchResult(searchTerm) {
    const response = await apiClient.get(
      `${apiBaseUrl}/Warranty/search?searchTerm=${searchTerm}`
    );
    setSearchResult(response.data.warrantySearch);
    setSearchCache(response.data.warrantySearch);
  }

  async function handleSearch(event) {
    const term = event.target.value;
    setSearchTerm(term);
  }

  const handleBlur = () => {
    setTimeout(() => setSearchResult([]), 100);
  };

  const handleOnFocus = () => {
    if(searchCache.length > 0){
      setSearchResult(searchCache);
    }
    else{
      setSearchResult([]);
    }
  }

  const handleResultClick = async (serial, type) => {
    if(serial.trim() !== "" && type !== null){
      try{
        const response = await apiClient.get(
          `${apiBaseUrl}/Warranty/deviceinfo?serialNumber=${serial}&type=${type}`
        )
        setDeviceData(response.data);
        setSelectedType(type);
      } catch (err){
        console.error(err);
      }
    }
  }
 
  return (
    <div className="flex min-h-screen">
      <div className="w-64 bg-gray-100 flex flex-column">
        <Sidebar />
      </div>

      <div className="flex-grow p-5">
        <h1 className="text-2xl font-bold text-blue-600 mb-4">
          Senzo Warranty
        </h1>
        <div className="relative">
          <input
            type="text"
            placeholder="Search for Gateway or Device serial number..."
            value={searchTerm}
            onChange={handleSearch}
            onBlur={handleBlur}
            onFocus={handleOnFocus}
            className="border p-2 w-full"
            ref={searchTimeoutRef}
          />

          {/* Display search results */}
          {searchResult.length > 0 && (
            <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-10 max-h-48 overflow-y-auto">
              {searchResult.map((result, index) => (
                <div
                  key={index}
                  className="p-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() => handleResultClick(result.serialNumber, result.type)}
                >
                  {result.serialNumber} ({result.type})
                </div>
              ))}
            </div>
          )}
        </div>

        {deviceData && (<WarrantyContent deviceData={deviceData} type={selectedType}/>)}
      </div>
    </div>
  );
}

export default Warranty;
