import Sidebar from "../sidebar/sidebar";
import GatewayStatusContent from "./gateway_status_content";
function GatewayStatusPage(){
    return(
        <>
      <div className="flex min-h-screen">
        {/* Sidebar */}

        <div className="w-64 bg-gray-100 flex flex-column">
          <Sidebar />
        </div>

        {/* Content (Table) */}
        <div className="flex-grow p-5">
          <h1 className="text-2xl font-bold text-blue-600 mb-4">
            Gateway Activity
          </h1>
          <GatewayStatusContent/>
        </div>
      </div>
    </>
    );
}

export default GatewayStatusPage; 