import React, { useEffect, useState, useContext } from "react";
import apiClient from "../utils/axiosConfig";
import TrialTab from "./trial_tab";
import { useAuth } from '../dataContext/AuthContext';
import ActiveTab from "./active_tab";
import ExpiredTab from "./expired_tab";
import RenewTab from "./renew_tab";
import SuspendedTab from "./suspended_tab";
import PaymentInProgressTab from "./payment_in_progress_tab";
import PaginationButtons from "./pagination_buttons";

function CmsMainContent() {
  const [selectedStatus, setSelectedStatus] = useState({name: "Expired", id: 3});
  const [statuses, setStatuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [cmsRecords, setCmsRecords] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { organizationId } = useAuth();
  const fetchStatus = async () => {
    try {
      const status = await apiClient.get(`${apiBaseUrl}/cms/allStatus`);
      if (status) {
        setStatuses(status.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePageChange = (pageNumber) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(pageNumber); 
      fetchRecords(selectedStatus.statusId, pageNumber, pageSize);
      setIsTransitioning(false);
    }, 300);
  };

  const renderTabContent = (records) => {
    switch (selectedStatus.name) {
      case "Trial":
        return (
          <TrialTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
      case "Active":
        return (
          <ActiveTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
      case "Expired":
        return (
          <ExpiredTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
      case "Renew":
        return (
          <RenewTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
      case "Suspended":
        return (
          <SuspendedTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
        case "Payment in Progress":
        return (
          <PaymentInProgressTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
    }
  };

  const handleStatusChange = (status) => {
    setSelectedStatus({name: status.statusName, id: status.statusId})
    setCurrentPage(1);
    fetchRecords(status.statusId, 1, pageSize);
  }
  

  const fetchRecords = async (statusId, pageNumber, pageSize) => {
    try {
      const records = await apiClient.get(`${apiBaseUrl}/cms/cmsrecords?organizationId=${organizationId}&statusId=${statusId}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
      
      if(records){
        setCmsRecords(records.data.data.records);
        setCurrentPage(records.data.data.pageNumber);
        setPageSize(records.data.data.pageSize);
        setTotalPage(records.data.data.totalPage);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <div className="flex-col">
      <div className="flex  justify-start space-x-8 overflow-x-auto border-b border-gray-300">
        {statuses.map((tab) => (
          <button
            key={tab.statusName}
            className={`py-2 text-sm font-medium transition-colors duration-300
              ${
                selectedStatus.name === tab.statusName
                  ? "text-black border-b-2 border-black"
                  : "text-gray-500"
              }
              hover:text-black`}
            onClick={() => handleStatusChange(tab)}
          >
            {tab.statusName}
          </button>
        ))}
      </div>
      <div className="p-4">{renderTabContent(cmsRecords)}</div>
      <div>
        <PaginationButtons currentPage={currentPage} totalPage={totalPage} handlePageChange={handlePageChange} />
      </div>
    </div>
  );
}
export default CmsMainContent;
