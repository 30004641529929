function PaginationButtons({ currentPage, totalPage, handlePageChange, isTransitioning }) {
    
    if (!totalPage || totalPage <= 1) return null;

    const pageButtons = [];
    const totalVisiblePages = 5;
    const startPage = Math.max(
        1,
        currentPage - Math.floor(totalVisiblePages / 2)
    );
    const endPage = Math.min(
        totalPage,
        startPage + totalVisiblePages - 1
    );

    // Always show the first page
    if (startPage > 1) {
        pageButtons.push(
            <button
                key={1}
                onClick={() => handlePageChange(1)}
                disabled={isTransitioning}
                className={`mx-1 px-3 py-1 rounded ${
                    currentPage === 1 ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
            >
                1
            </button>
        );
        if (startPage > 2) {
            pageButtons.push(
                <span key="start-ellipsis" className="mx-1 px-3 py-1">
                    ...
                </span>
            );
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
            <button
                key={i}
                onClick={() => handlePageChange(i)}
                disabled={isTransitioning}
                className={`mx-1 px-3 py-1 rounded ${
                    currentPage === i ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
            >
                {i}
            </button>
        );
    }

    if (endPage < totalPage) {
        if (endPage < totalPage - 1) {
            pageButtons.push(
                <span key="end-ellipsis" className="mx-1 px-3 py-1">
                    ...
                </span>
            );
        }
        pageButtons.push(
            <button
                key={totalPage}
                onClick={() => handlePageChange(totalPage)}
                disabled={isTransitioning}
                className={`mx-1 px-3 py-1 rounded ${
                    currentPage === totalPage ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
            >
                {totalPage}
            </button>
        );
    }

    return (
        <div className="flex items-center justify-center gap-1 mt-4">
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1 || isTransitioning}
                className={`mx-1 px-3 py-1 rounded ${
                    currentPage === 1 ? "bg-gray-100 text-gray-400" : "bg-gray-200 hover:bg-gray-300"
                }`}
            >
                Previous
            </button>
            
            {/* Page buttons */}
            {pageButtons}
            
            {/* Next button */}
            <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPage || isTransitioning}
                className={`mx-1 px-3 py-1 rounded ${
                    currentPage === totalPage ? "bg-gray-100 text-gray-400" : "bg-gray-200 hover:bg-gray-300"
                }`}
            >
                Next
            </button>
        </div>
    );
}

export default PaginationButtons;