import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaKey, FaUser } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import apiClient from "../utils/axiosConfig";
import { useAuth } from "../dataContext/AuthContext";

function SettingsContent() {
  return (
    <div className="p-6 w-full bg-white shadow-lg rounded-lg max-w-3xl mx-auto">
      {/* Option Item */}
      <ApiKeyRow />
      <UserRow/>
    </div>
  );
}

function UserRow(){
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isShowUserModalOpen, setIsShowUserModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [isPasswordSame, setIsPasswordSame] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [userOrganization, setUserOrganization] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [rolesFromApi, setRolesFromApi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRolesFetched, setIsRoleFetched] = useState(false);
  const [isOrganizationFetched, setIsOrganizationFetched] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  const [isShowUserDrop, setIsShowUserDrop] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleUserModalClose = () => {
    setIsUserModalOpen(false);
    setUserEmail("");
    setErrorMessage("");
    setUserPassword("");
    setRetypePassword("");
    setUserRole("");
  };

  useEffect(() => {
    if (userPassword !== retypePassword) {
      setIsPasswordSame(false);
    } else {
      setIsPasswordSame(true);
    }
  }, [userPassword, retypePassword]);

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      var dto = {
        email: userEmail,
        password: userPassword,
        role: userRole,
        organizationId: userOrganization
      };

      await apiClient.post(`${apiBaseUrl}/Auth/createNewUser`, dto);
      alert("User created successfully!");
      handleUserModalClose();
    } catch (err) {
      console.error(err);
      setErrorMessage(err.response.data);
      //alert(err.message);
    }
    setIsLoading(false);
  };

  const handleOrganizationChange = (id) => {
    setUserOrganization(id);
  } 
  const fetchRolesAsync = async () => {
    if (!isRolesFetched) {
      try {
        var roles = await apiClient.get(`${apiBaseUrl}/Auth/getRoles`);
        console.log(roles.data);
        setRolesFromApi(roles.data.roles);
        setIsRoleFetched(true);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  const fetchOrganizationAsync = async () => {
    if(!isOrganizationFetched){
      try{
        var organization = await apiClient.get(`${apiBaseUrl}/Cms/organizations`);
        setOrganizations(organization.data.data);
        setIsOrganizationFetched(true);
      } catch (err){
        console.error(err);
      }
    }
  }

  return (
    <>
      <div className="flex flex-col items-center justify-start p-4 border rounded-lg mt-4">
        <div className="flex flex-row justify-start w-full">
          <div className="flex items-center space-x-3">
            <FaUser className="text-blue-500 text-2xl" />
            <div>
              <p className="text-lg font-medium">User Managemement</p>
            </div>
          </div>
          <button
            onClick={() => setIsShowUserDrop(!isShowUserDrop)}
            className="text-2xl font-semibold text-blue-500 flex items-center ml-auto"
          >
            {isShowUserDrop ? <FiChevronUp /> : <FiChevronDown />}
          </button>
        </div>

        {isShowUserDrop && (
          <div className="flex flex-col w-full">
            <div className="flex flex-row pr-12 pl-12 mt-3 justify-start w-full">
              <div className="flex items-center space-x-3">
                <p className="text-lg font-medium">Create User</p>
              </div>

              <button
                onClick={() => setIsUserModalOpen(true)}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-0 rounded-md ml-auto h-8"
              >
                Create
              </button>
            </div>
            <div className="flex flex-row pr-12 pl-12 mt-3 justify-start w-full">
              <div className="flex items-center space-x-3">
                <p className="text-lg font-medium">Show Users</p>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={isUserModalOpen}
        onRequestClose={() => handleUserModalClose()}
        className="flex items-center justify-center h-full"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
          <h3 className="text-xl font-semibold mb-4">Create user</h3>

          <form onSubmit={handleUserSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Retype Password
              </label>
              <input
                type="password"
                value={retypePassword}
                onChange={(e) => setRetypePassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            {!isPasswordSame && (
              <p className="text-red-500 font-sm">
                Two passwords must match each other!
              </p>
            )}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                User Organization
              </label>
              <select
                type="select"
                value={userOrganization}
                onClick={(e) => fetchOrganizationAsync()}
                onChange={(e) => handleOrganizationChange(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                <option value="">Select user's organization</option>
                {organizations.map((organization, index) => (
                  <option key={index} value={organization.organizationId}>
                    {organization.organizationName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                User Role
              </label>
              <select
                type="select"
                value={userRole}
                onClick={(e) => fetchRolesAsync()}
                onChange={(e) => setUserRole(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                <option value="">Select user's role</option>
                {rolesFromApi.map((role, index) => (
                  <option key={index} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
            {errorMessage && (
              <p className="text-red-500 text-sm">{errorMessage}</p>
            )}
            <button
              disabled={!isPasswordSame || isLoading}
              type="submit"
              className={`w-full ${
                isPasswordSame
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-gray-500 hover:bg-gray-600"
              }  text-white font-semibold px-4 py-2 rounded-md`}
            >
              {isLoading ? "Creating..." : "Submit"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}
function ApiKeyRow() {
  const [isShowKeyDrop, setIsShowKeyDrop] = useState(false);
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [isShowKeyModalOpen, setIsShowKeyModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [apiKeyName, setApiKeyName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [apiList, setApiList] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleApiKeyModalClose = () => {
    setIsKeyModalOpen(false);
    setApiKey("");
    setEmail("");
    setPassword("");
  };
  const handleApiKeyListOpen = () => {
    if (isShowKeyModalOpen === false) {
      fetchApiKeyList();
    }
    setIsShowKeyModalOpen(!isShowKeyModalOpen);
  };
  const fetchApiKeyList = async () => {
    try {
      var result = await apiClient.get(`${apiBaseUrl}/Auth/getApiKeys`);
      setApiList(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  const updateApiKeyStatus = async (hashedApiKey, status) => {
    var formdata = {
      hashedKey: hashedApiKey,
      isValid: status,
    };
    try {
      var result = await apiClient.put(
        `${apiBaseUrl}/Auth/updateKeyStatus`,
        formdata
      );

      if (result) {
        fetchApiKeyList();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const copyToClipboard = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000); // Clear message after 2 seconds
    }
  };

  const deleteApiKey = async (hashedKey, apiKeyName) => {
    if (window.confirm(`Are you sure you want to delete api key "${apiKeyName}"`)) {
      try {
        var result = await apiClient.delete(
          `${apiBaseUrl}/Auth/deleteKey?hashedKey=${hashedKey}`
        );
        if (result) {
          alert(result.data);
          fetchApiKeyList();
        }
      } catch (err) {
        alert(err.data);
      }
    } else {
      return;
    }
  };
  const handleAuthSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await apiClient.post(
        `${apiBaseUrl}/Auth/generateApiKey?apiKeyName=${apiKeyName}`,
        { email, password }
      );

      if (response.status === 200 && response.data.apiKey) {
        setApiKey(response.data.apiKey);
        setEmail("");
        setPassword("");
        setApiKeyName("");
        setErrorMessage("");
        fetchApiKeyList();
      } else {
        setErrorMessage("Authentication failed. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please check your credentials.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start p-4 border rounded-lg">
      <div className="flex flex-row justify-start w-full">
        <div className="flex items-center space-x-3">
          <FaKey className="text-blue-500 text-2xl" />
          <div>
            <p className="text-lg font-medium">API Key Management</p>
          </div>
        </div>
        <button
          onClick={() => setIsShowKeyDrop(!isShowKeyDrop)} // Toggle isShowKey on click
          className="text-2xl font-semibold text-blue-500 flex items-center ml-auto"
        >
          {isShowKeyDrop ? <FiChevronUp /> : <FiChevronDown />}
        </button>
      </div>

      {isShowKeyDrop && (
        <div className="flex flex-col w-full">
          <div className="flex flex-row pr-12 pl-12 mt-3 justify-start w-full">
            <div className="flex items-center space-x-3">
              <p className="text-lg font-medium">Generate Api Key</p>
            </div>

            <button
              onClick={() => setIsKeyModalOpen(true)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-0 rounded-md ml-auto h-8"
            >
              Generate
            </button>
          </div>
          <div className="flex flex-row pr-12 pl-12 mt-3 justify-start w-full">
            <div className="flex items-center space-x-3">
              <p className="text-lg font-medium">Show Api Key</p>
            </div>

            <button
              onClick={() => handleApiKeyListOpen()}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-0 rounded-md ml-auto h-8"
            >
              {isShowKeyModalOpen ? "Close" : "Show"}
            </button>
          </div>
          {isShowKeyModalOpen && (
            <ApiKeyList
              apiKeys={apiList}
              updateApiKeyStatus={updateApiKeyStatus}
              deleteApiKey={deleteApiKey}
            />
          )}
        </div>
      )}
      <Modal
        isOpen={isKeyModalOpen}
        onRequestClose={() => handleApiKeyModalClose()}
        className="flex items-center justify-center h-full"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <h3 className="text-xl font-semibold mb-4">
            {apiKey ? "Your API Key" : "Authenticate to Generate API Key"}
          </h3>

          {apiKey ? (
            <div>
              <div className="flex justify-between mb-4 flex-row items-center">
                <p
                  className="bg-gray-800 text-white text-lg font-mono p-2 rounded-md break-all overflow-x-auto w-full"
                  style={{ maxWidth: "calc(100% - 3rem)" }}
                >
                  {apiKey}
                </p>
                <button
                  onClick={copyToClipboard}
                  className="text-blue-500 hover:text-blue-700 flex items-center ml-2"
                >
                  <FiCopy className="text-xl" />
                  <span className="ml-1">Copy</span>
                </button>
              </div>
              {copySuccess && (
                <p className="text-green-500 text-sm mb-4">{copySuccess}</p>
              )}
              <p className="text-sm text-gray-600">
                Please copy it elsewhere because you will lose it forever after
                closing this window.
              </p>
              <button
                onClick={() => handleApiKeyModalClose()}
                className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
              >
                Close
              </button>
            </div>
          ) : (
            <form onSubmit={handleAuthSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Key Name
                </label>
                <input
                  type="text"
                  value={apiKeyName}
                  onChange={(e) => setApiKeyName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              {errorMessage && (
                <p className="text-red-500 text-sm">{errorMessage}</p>
              )}
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
                disabled={isLoading}
              >
                {isLoading ? "Authenticating..." : "Submit"}
              </button>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
}
function ApiKeyList({ apiKeys, updateApiKeyStatus, deleteApiKey }) {
  return (
    <div className="p-6 w-full bg-white shadow-lg rounded-lg max-w-3xl mx-auto">
      <div className="space-y-4">
        {apiKeys.map((apiKey) => (
          <div
            key={apiKey.hashedKey}
            className="p-4 border rounded-lg bg-gray-50"
          >
            <div className="flex items-center space-x-3 mb-2">
              <FaKey className="text-blue-500 text-2xl" />
              <div className="flex flex-row w-full justify-center">
                <div className="flex flex-col justify-start">
                  <p className="text-lg font-semibold">{apiKey.name}</p>
                  <p className="text-sm text-gray-500">
                    Created at: {new Date(apiKey.createdAt).toLocaleString()}
                  </p>
                </div>
                <div className="ml-auto">
                  <button
                    onClick={() =>
                      updateApiKeyStatus(
                        apiKey.hashedKey,
                        apiKey.isValid === 0 ? 1 : 0
                      )
                    }
                    className={`${
                      apiKey.isValid === 1
                        ? "bg-red-500 hover:bg-red-600"
                        : "bg-green-500 hover:bg-green-600"
                    } text-white font-semibold px-4 py-0 h-8 rounded-md ml-auto mr-2`}
                  >
                    {apiKey.isValid === 1 ? "Revoke" : "Enable"}
                    
                  </button>
                  <button
                    onClick={() => deleteApiKey(apiKey.hashedKey, apiKey.name)}
                    className={`bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-0 h-8 rounded-md ml-auto ml-2`}

                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="space-y-1">
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Hashed Key:</span>
                <span className="text-gray-800 break-words">
                  {apiKey.hashedKey}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Is Valid:</span>
                <span className="text-gray-800">
                  {apiKey.isValid === 1 ? "Yes" : "No"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">
                  Last Used At:
                </span>
                <span className="text-gray-800">
                  {new Date(apiKey.lastUsedAt).toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
function CmsOrganizationRow() {
  
}
export default SettingsContent;
