import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();
function getUserRolesFromToken(token) {
  if (!token) return [];

  try {
    const decodedToken = jwtDecode(token);
    const roles =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    return Array.isArray(roles) ? roles : roles ? [roles] : [];
  } catch (error) {
    console.error("Invalid token", error);
    return [];
  }
}

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [roles, setRoles] = useState([]);
  const [organizationId, setOrganizationId] = useState(0);

  const updateToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem("jwtToken", newToken);
    const decodedRoles = getUserRolesFromToken(newToken);
    setRoles(decodedRoles);
  };

  const updateOrganizationId = (id) => {
    setOrganizationId(id);
  }

  useEffect(() => {
    const savedToken = localStorage.getItem("jwtToken");
    if (savedToken) {
      setToken(savedToken);
      const decodedRoles = getUserRolesFromToken(savedToken);
      setRoles(decodedRoles);
    }
  }, []);

  const logout = () => {
    setToken(null);
    setRoles([]);
    localStorage.removeItem("jwtToken");
  };

  return (
    <AuthContext.Provider value={{ token, roles, updateToken, logout, updateOrganizationId, organizationId }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
