import { useState, useEffect, useContext, useRef } from "react";
import apiClient from "../utils/axiosConfig";
import { FaSync } from "react-icons/fa";
import { GatewayDataContext } from "../dataContext/GatewayDataContext";
import { useNavigate } from "react-router-dom";
import { Outlet } from 'react-router-dom';

function GatewayAnalyticsContent() {
  const { data, fetchData } = useContext(GatewayDataContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchCache, setSearchCache] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const searchTimeoutRef = useRef();

  const fetchSearchResult = async (searchTerm) => {
    const result = await apiClient.get(`${apiBaseUrl}/analytics/searchgateway?searchTerm=${searchTerm}`);

    setSearchData(result.data.gateways);
    setSearchCache(result.data.gateways);
  }

  const handleSearch = (event) => {
    const term = event.target.value;
    if (
      event.target.value === null ||
      event.target.value === undefined ||
      event.target.value === ""
    ) {
      setSearchData([]);
      setSearchCache([]);
      setSearchTerm(event.target.value);
      return;
    }
    setSearchTerm(term);
    try {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      searchTimeoutRef.current = setTimeout(() => {
        fetchSearchResult(event.target.value);
      }, 500);
    } catch (err) {
      console.error(err);
    }
  };

  const handleBlur = () => {
    setTimeout(() => setSearchData([]), 100);
  };
  const handleFocus = () => {
    if (searchCache.length > 0) {
      setSearchData(searchCache);
    } else {
      setSearchData([]);
    }
  };
  const handleRefresh = () => {
    fetchData(2);
    fetchData(60);
    fetchData(720);
    fetchData(1440);
    fetchData(10080);
  };

  const handleNavigateToGatewayPage = () => {
    navigate("/gatewayConnectStatus");
  };
  const handleResultClick = async (serialNumber) => {
    navigate("/analytics/connectivity", { state: { serialNumber: serialNumber } });
  };

  return (
    <div className="h-auto w-auto bg-blue-100 p-4 pb-8">
      <div className="mb-4 mr-4 ml-4 mt-4 relative">
        <input
          type="text"
          placeholder="Search for Gateway's Serial Number to view connectivity history"
          value={searchTerm}
          onChange={handleSearch}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className="border p-2 w-full border-blue-500 rounded-lg relative"
        />
        {searchData.length > 0 && (
          <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-10 max-h-48 overflow-y-auto">
            {searchData.map((result, index) => (
              <div
                key={index}
                className="p-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => handleResultClick(result.gatewaySN)}
              >
                {result.gatewaySN}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-row mt-8 items-center">
        <h1 className="font-bold">Gateway Connection Data</h1>
        <div className="ml-auto flex flex-row">
          <button
            onClick={handleNavigateToGatewayPage}
            className="flex items-center bg-blue-500 hover:bg-blue-600 text-white font-bold h-8 px-4 rounded mr-5"
          >
            <FaSync className="mr-2 hover:animate-spin" />
            See Gateway
          </button>
          <button
            onClick={handleRefresh}
            className="flex items-center bg-blue-500 hover:bg-blue-600 text-white font-bold h-8 px-4 rounded"
          >
            <FaSync className="mr-2 hover:animate-spin" />
            Refresh
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 mb-5">
        <div className="flex flex-col">
          <div className="bg-blue-500 text-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold">
              {data.currentlyOnline} / {data.totalGateway}
            </h3>
            <p>Currently Online</p>
          </div>
          <p className="text-xs">*Updates every minute</p>
        </div>
        <div className="flex flex-col">
          <div className="bg-green-500 text-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold">
              {data.pastHour} / {data.totalGateway}
            </h3>
            <p>Past Hour</p>
          </div>
          <p className="text-xs">*Updates every hour</p>
        </div>
        <div className="flex flex-col">
          <div className="bg-yellow-500 text-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold">
              {data.pastTwelveHours} / {data.totalGateway}
            </h3>
            <p>Past 12 Hour</p>
          </div>
          <p className="text-xs">*Updates every hour</p>
        </div>
        <div className="flex flex-col">
          <div className="bg-purple-500 text-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold">
              {data.pastDay} / {data.totalGateway}
            </h3>
            <p>Past Day</p>
          </div>
          <p className="text-xs">*Updates every hour</p>
        </div>
        <div className="flex flex-col">
          <div className="bg-red-500 text-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold">
              {data.pastWeek} / {data.totalGateway}
            </h3>
            <p>Past Week</p>
          </div>
          <p className="text-xs">*Updates every week</p>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default GatewayAnalyticsContent;
