import axios from "axios";
import Cookies from 'js-cookie';

const apiClient = axios.create({
    baseURL: 'https://dashboard.crossguard.senzo.com.my',
    withCredentials: true,
});
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("jwtToken");
        if(token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config; 
    }, 
    (error) => {
        return Promise.reject(error);
    }
);

const deleteCookie = (cookieName) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if(error.response.status === 401 && !originalRequest._retry){
            originalRequest._retry = true;
            const refreshToken = Cookies.get('refreshToken');
            try{
                const tokenRequest = {
                    AccessToken: null,
                    RefreshToken: refreshToken
                }
                const res = await apiClient.post(
                  `${apiBaseUrl}/Auth/refresh`,
                  tokenRequest,
                  {
                    withCredentials: true, 
                    headers: {
                      "Content-Type": "application/json", 
                    },
                  }
                );
                const {token} = res.data;

                localStorage.setItem('jwtToken', token);

                apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                originalRequest.headers['Authorization'] = `Bearer ${token}`;

                return apiClient(originalRequest); 
            } catch (refreshError) {
                console.log("Refresh token expired");
                //await apiClient.post(`${apiBaseUrl}/Auth/logout`);
                localStorage.removeItem('jwtToken');
                localStorage.removeItem("isAuthenticated");
                deleteCookie('refreshToken');
                window.location.href = "/login";
            }            
        }
        return Promise.reject(error);
    }
)

export default apiClient;