import React, { useEffect, useState } from "react";
import { AlertCircle } from 'lucide-react';
import apiClient from "../utils/axiosConfig";

function PaymentInProgressTab({records, currentPage, pageSize}){
    const [selectedRows, setSelectedRows] = useState([]);
    const handleRowSelect = async (id) => {
        const newSelectedRows = selectedRows.includes(id)
          ? selectedRows.filter((rowId) => rowId !== id)
          : [...selectedRows, id];
          
        setSelectedRows(newSelectedRows);
      };
      if (!records || records.length === 0) {
        return (
          <div className="min-h-[300px] flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border border-gray-200">
            <AlertCircle className="h-12 w-12 text-gray-400 mb-3" />
            <h3 className="text-lg font-medium text-gray-900 mb-1">No Records Found</h3>
            <p className="text-sm text-gray-500">There are currently no active subscription records to display.</p>
          </div>
        );
      }
      return (
        <div className="table-container">
          <table className="w-full border border-gray-300">
            <thead>
              <tr>
                <th>Select</th>
                <th>Index</th>
                <th>Gateway SN</th>
                <th>Subscription Start</th>
                <th>Subscripton End</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => {
                return (
                  <tr id={record.id}>
                    <td className="border p-2">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(record.id)}
                        onChange={() => handleRowSelect(record.id)}
                      />
                    </td>
                    <td className="border p-2">
                      {index + 1 + (currentPage - 1) * pageSize}
                    </td>
                    <td className="border p-2">
                      {record.gatewaySN}
                    </td>
                    <td className="border p-2">
                    {new Date(record.subscriptionStart).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                          .toUpperCase()}
                    </td>
                    <td className="border p-2">
                    {new Date(record.subscriptionEnd).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                          .toUpperCase()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
}

export default PaymentInProgressTab; 