import { Navigate } from "react-router-dom";
import { useAuth } from "../dataContext/AuthContext";

const ProtectedRoute = ({children, requiredRoles}) => {
    const { roles } = useAuth();
    const userRoles = Array.isArray(roles) ? roles : [];

    const token = localStorage.getItem("jwtToken");
    const isAuthorized = token && requiredRoles.some(role => userRoles.includes(role));
    return isAuthorized ? children : <Navigate to="/unauthorized" />;
}

export default ProtectedRoute;