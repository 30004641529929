import { useState, useEffect, useContext, useRef } from "react";
import apiClient from "../utils/axiosConfig";
import { FaSync, FaDownload  } from "react-icons/fa";
import { GatewayDataContext } from "../dataContext/GatewayDataContext";
import { useNavigate } from "react-router-dom";

function GatewayStatusContent(){
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalPage, setTotalPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setIsAscending] = useState();
    const [sortBy, setSortBy] = useState("");
    const [requestedDateTime, setRequestedDateTime] = useState("");

    const searchTimeoutRef = useRef(null);

    useEffect(() => {
       fetchData(1, pageSize, "", "LastPingedTime", false);
    }, []);

    const handleSortClick = (sortingColumn, sortDirection) => {
      if(sortingColumn !== sortBy){
        sortDirection = false;
      }
      setSortBy(sortingColumn); 
      setIsAscending(!sortDirection);
      fetchData(currentPage, pageSize, searchTerm, sortingColumn, !sortDirection);
    } 

    const handleSearch = (event) => {
      const newSearchTerm = event.target.value;
      setSearchTerm(newSearchTerm);
      setCurrentPage(1);

      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      searchTimeoutRef.current = setTimeout(() => {
        setCurrentPage(1);
        fetchData(1, pageSize, newSearchTerm, sortBy, isAscending);
      }, 500);
    };

    const handlePageChange = (pageNumber) => {
        if(pageNumber === currentPage) {
            return;
        }
        setIsTransitioning(true);
        setTimeout(() => {
          setCurrentPage(pageNumber); 
          setIsTransitioning(false);
        }, 300);

        fetchData(pageNumber, pageSize, searchTerm, sortBy, isAscending);
    };

    const handleRefresh = () => {
        fetchData(currentPage, pageSize, searchTerm, sortBy, isAscending);
    }
    
    const handleCsvDownloadClicked = async () => {
      try{
        const response = await apiClient.get(
          `${apiBaseUrl}/analytics/generate_csv`, {
            responseType: 'blob',
          }
        );
  
        const blob = new Blob([response.data], {type: 'text/csv'});
  
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'gateway_activity.csv');
  
        document.body.appendChild(link);
        link.click();
  
        link.remove();
      } catch (err){
        console.error(err);
      }
    }
    
    const fetchData = async (pageNumber, pageSize, searchTerm, sortBy, isAscending) => {
      try {
        var result = await apiClient.get(
          `${apiBaseUrl}/analytics/getallgateway?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm ?? ""}&sortBy=${sortBy}&sortDirection=${isAscending ? "asc" : "dsc"}`
        );

        setData(result.data.gateways);
        setCurrentPage(result.data.pageNumber);
        setTotalPage(result.data.totalPage);
        setTotalCount(result.data.totalCount);
        const requestedDateTimeString = new Date(
          result.data.requestedDateTime
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "short", 
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).toUpperCase();
        setRequestedDateTime(requestedDateTimeString);
      } catch (err) {
        console.error(err);
      }
    };
    const renderSortArrow = (column) => {
      if (sortBy === column) {
        return isAscending ? '↑' : '↓';
      }
      return null;
    };
    const renderPaginationButtons = () => {
        const pageButtons = [];
        const totalVisiblePages = 5; 
        const startPage = Math.max(
          1,
          currentPage - Math.floor(totalVisiblePages / 2)
        );
        const endPage = Math.min(
          totalPage,
          currentPage + Math.floor(totalVisiblePages / 2)
        );
    
        if (startPage > 1) {
          pageButtons.push(
            <button
              key={1}
              onClick={() => handlePageChange(1)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === 1 ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
            >
              1
            </button>
          );
          if (startPage > 2) {
            pageButtons.push(
              <span key="start-ellipsis" className="mx-1 px-3 py-1">
                ...
              </span>
            );
          }
        }
    
        for (let i = startPage; i <= endPage; i++) {
          pageButtons.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === i ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
            >
              {i}
            </button>
          );
        }
    
        if (endPage < totalPage) {
          if (endPage < totalPage - 1) {
            pageButtons.push(
              <span key="end-ellipsis" className="mx-1 px-3 py-1">
                ...
              </span>
            );
          }
          pageButtons.push(
            <button
              key={totalPage}
              onClick={() => handlePageChange(totalPage)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === totalPage ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
            >
              {totalPage}
            </button>
          );
        }
    
        return pageButtons;
      };

      return (
        <div className="h-auto w-auto bg-blue-100 p-4 pb-8">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search for gateway serial number..."
              value={searchTerm}
              onChange={handleSearch}
              className="border p-2 w-full"
            />
          </div>
          <div className="mt-4 mb-4 flex flex-row">
          <button
            onClick={handleCsvDownloadClicked}
            className="flex items-center bg-blue-500 hover:bg-blue-600 text-white font-bold h-8 px-4 rounded"
          >
            <FaDownload className="mr-2" />
            Download CSV
          </button>
          <button
            onClick={handleRefresh}
            className="ml-auto flex items-center bg-blue-500 hover:bg-blue-600 text-white font-bold h-8 px-4 rounded"
          >
            <FaSync className="mr-2 hover:animate-spin" />
            Refresh
          </button>
          
          </div>
          <h3>Data as of {requestedDateTime}</h3>
          <table
            className={`table-auto w-full border-collapse border border-gray-600 transition-opacity duration-300 ${
              isTransitioning ? "opacity-0" : "opacity-100"
            }`}
          >
            <thead>
              <tr className="bg-gray-500">
                <th className="border p-2 text-white">Index</th>
                <th onClick={() => handleSortClick("GatewaySN", isAscending)} className="border p-2 text-white hover:cursor-pointer">Gateway SN {renderSortArrow("GatewaySN")}</th>
                <th onClick={() => handleSortClick("LastPingedTime", isAscending)} className="border p-2 text-white hover:cursor-pointer">Last Pinged Time {renderSortArrow("LastPingedTime")}</th>
                <th onClick={() =>handleSortClick("LastLoginTime", isAscending)} className="border p-2 text-white hover:cursor-pointer">Last Login Time {renderSortArrow("LastLoginTime")}</th>
                <th className="border p-2 text-white">Last Online</th>
                <th onClick={() => handleSortClick("IpAddress", isAscending)} className="border p-2 text-white hover:cursor-pointer">Ip Address {renderSortArrow("IpAddress")}</th>
                <th onClick={() => handleSortClick("IpPort", isAscending)} className="border p-2 text-white hover:cursor-pointer">Ip Port {renderSortArrow("IpPort")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => {
                const convertMinutesToDaysHourMinutesAndSeconds = (
                  totalMinutes
                ) => {
                  const days = Math.floor(totalMinutes / (60 * 24));
                  const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
                  const minutes = Math.floor(totalMinutes % 60);
                  const seconds = Math.floor((totalMinutes * 60) % 60);

                  return {
                    days,
                    hours,
                    minutes,
                    seconds,
                  };
                };

                const result = convertMinutesToDaysHourMinutesAndSeconds(
                  row.lastOnlineIntervalInMinutes
                );
                return (
                  <tr className="bg-white">
                    <td className="border p-2 text-black">
                      {index + 1 + (currentPage - 1) * pageSize}
                    </td>
                    <td className="border p-2 text-black">{row.gatewaySN}</td>
                    <td className="border p-2 text-black">
                      {new Date(row.lastPingedTime)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                        .toUpperCase()}
                    </td>
                    <td className="border p-2 text-black">
                      {new Date(row.lastLoginTime).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                        .toUpperCase()}
                    </td>
                    <td className="border p-2 text-black">
                      {`${result.days} days, ${result.hours} hours, ${result.minutes} minutes, ${result.seconds} seconds ago`}
                    </td>
                    <td className="border p-2 text-black">
                      {row.lastIpAddress}
                    </td>
                    <td className="border p-2 text-black">{row.lastIpPort}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-center mt-4">
            {renderPaginationButtons()}
          </div>
        </div>
      );
}

export default GatewayStatusContent;