import React, { useState, useEffect, useRef } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import apiClient from "../utils/axiosConfig";

const WhiteListTable = () => {
  const rowsPerPage = 50;
  const [loading, setLoading] = useState(true);
  const pageSize = 50;
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [editValues, setEditValues] = useState({
    serialNumber: '',
    macAddress: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [totalCount, setTotalCount] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  const [type, setType] = useState("0");

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const searchTimeoutRef = useRef(null);

  useEffect(() => {
    const typeInt = parseInt(type)
    fetchData(currentPage, pageSize, searchTerm, typeInt);
  }, [currentPage]);

  useEffect(() => {
    const typeInt = parseInt(type)
    fetchData(currentPage, pageSize, searchTerm, typeInt);
  }, [isRefresh]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValues({
      ...editValues,
      [name]: value
    });
  };
  
  const handleSelectChange = (e) => {
    setType(e.target.value);
    setCurrentPage(1);
    const typeInt = parseInt(e.target.value)
    fetchData(currentPage, pageSize, searchTerm, typeInt);
  }
  const renderPaginationButtons = () => {
    const pageButtons = [];
    const totalVisiblePages = 5; // Number of pages to show around the current page
    const startPage = Math.max(
      1,
      currentPage - Math.floor(totalVisiblePages / 2)
    );
    const endPage = Math.min(
      totalPage,
      currentPage + Math.floor(totalVisiblePages / 2)
    );

    // Always show the first page
    if (startPage > 1) {
      pageButtons.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === 1 ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pageButtons.push(
          <span key="start-ellipsis" className="mx-1 px-3 py-1">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === i ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPage) {
      if (endPage < totalPage - 1) {
        pageButtons.push(
          <span key="end-ellipsis" className="mx-1 px-3 py-1">
            ...
          </span>
        );
      }
      pageButtons.push(
        <button
          key={totalPage}
          onClick={() => handlePageChange(totalPage)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === totalPage ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          {totalPage}
        </button>
      );
    }

    return pageButtons;
  };

  const fetchData = async (pageNumber, pageSize, searchTerm, typeInt) => {
    setLoading(true);
   
    try {
;      const response = await apiClient.get(
        `${apiBaseUrl}/Whitelist/whitelists?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm ?? ""}&type=${typeInt}`
      );
      console.log( `${apiBaseUrl}/Whitelist/whitelists?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm ?? ""}&type=${typeInt}`);
      setTableData(response.data.whitelist);
      setCurrentPage(response.data.pageNumber); 
      setTotalPage(response.data.totalPage); 
      setTotalCount(response.data.totalCount); 
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }   
  };

  // if (loading) {
  //   // Show loading spinner or placeholder content while loading
  //   return <div className="text-center mt-6">Loading...</div>;
  // }

  const handleRowSelect = (guid) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(guid)
        ? prevSelectedRows.filter((rowId) => rowId !== guid)
        : [...prevSelectedRows, guid]
    );
  };

  const handleEditClick = (guid, serialNumber, macAddress) => {
    setEditRowId(guid);
    setEditValues({
      serialNumber,
      macAddress
    });
  };

  const handleDeleteClick = (guid) => {
    const filteredData = tableData.filter((row) => row.guid !== guid);
    setTableData(filteredData);
  };

  const handlePageChange = (pageNumber) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(pageNumber); // Move to the selected page
      setIsTransitioning(false);
    }, 300);
  };

  const saveEditedRow = async (guid, updatedValues) => {
    console.log(`Saving row with guid: ${guid}`, updatedValues);
    var formData = {
      guid: guid, 
      gatewaySN: updatedValues.serialNumber,
      macAddress: updatedValues.macAddress,
    }
    try{
      const response = await apiClient.patch(`${apiBaseUrl}/Whitelist/edit`, formData);
      console.log("Gateway edited successfully", response.data);
      const typeInt = parseInt(type, 10);
      fetchData(currentPage, pageSize, searchTerm, typeInt);
    } catch(error){
      console.error("Error editing gateway:", error);
    }
  };

  const handleGenerateCsvClicked = async () => {
    try{
      const response = await apiClient.get(
        `${apiBaseUrl}/Whitelist/generate_csv`, {
          responseType: 'blob',
        }
      );

      const blob = new Blob([response.data], {type: 'text/csv'});

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'whitelist.csv');

      document.body.appendChild(link);
      link.click();

      link.remove();
    } catch (err){
      console.error(err);
    }
  }
  const handleUploadClicked = async () => {
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
    setLoading(true);
    try {
      const response = await apiClient.post(
        `${apiBaseUrl}/Whitelist/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );
      console.log("File uploaded successfully:", response.data);
      setIsRefresh(!isRefresh);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
    }   
  };

  const handleSelectClicked = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    }
    event.target.value = null;
  };

  const handleDeleteFile = (fileIndex) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== fileIndex
    );
    setSelectedFiles(updatedFiles);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = tableData;

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setCurrentPage(1);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setCurrentPage(1);
      const typeInt = parseInt(type);
      fetchData(1, pageSize, newSearchTerm, typeInt); 
    }, 500); 
  }

  const handleSaveClick = (guid) => {
    saveEditedRow(guid, editValues);
    setEditRowId(null); 
  };
  
  
  return (
    <div className="container mx-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="border p-2 w-full"
        />
      </div>
      <div className="mb-4 h-auto flex flex-row w-full justify-between">
        <div className="flex flex-row space-x-4">
          <button
            className="w-24 h-8 flex justify-center items-center bg-blue-600 text-white rounded-lg transition-all duration-300 hover:bg-blue-700 hover:text-gray-200 mb-2"
            onClick={handleSelectClicked}
          >
            <span className="text-white">Select CSV</span>
          </button>
          <button
            className="w-32 h-8 flex justify-center items-center bg-blue-600 text-white rounded-lg transition-all duration-300 hover:bg-blue-700 hover:text-gray-200 mb-2"
            onClick={() => handleGenerateCsvClicked()}
          >
            <span className="text-white">Download CSV</span>
          </button>
        </div>
        <div className="flex flex-row space-x-4 items-center">
          <h3 className="font-bold">Category</h3>
          <select
            id="exampleDropdown"
            value={type}
            onChange={(event) => handleSelectChange(event)}
            className="border border-gray-300 rounded p-2"
          >
            <option value="0" disabled>
              -- Select an option --
            </option>
            <option value="0">View All</option>
            <option value="1">AMG1</option>
            <option value="2">CGH1</option>
            <option value="3">CGP1</option>
            <option value="4">CGU1</option>
          </select>
        </div>
      </div>
      <div className="flex flex-col">
        <input
          id="fileInput"
          type="file"
          accept=".csv"
          multiple
          className="hidden"
          onChange={handleFileChange}
        />
        {selectedFiles.length > 0 && (
          <div className="mt-2 text-sm text-gray-600 w-8">
            <strong>Selected files:</strong>
            <ul className="flex flex-row mb-4">
              {selectedFiles.map((file, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mr-4"
                >
                  <span>{file.name}</span>
                  {/* Delete Icon */}
                  <button
                    onClick={() => handleDeleteFile(index)}
                    className="text-red-500 ml-4 hover:text-red-700"
                  >
                    <FaTrash />
                  </button>
                </li>
              ))}
            </ul>
            <button
              className="w-24 h-8 flex justify-center items-center bg-blue-600 text-white rounded-lg transition-all duration-300 hover:bg-blue-700 hover:text-gray-200 mb-2"
              onClick={handleUploadClicked}
            >
              <span className="text-white">Upload CSV</span>
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-row space-x-4 mb-4">
        <h3 className="font-bold">Total Count : {totalCount}</h3>
      </div>
      {loading && (
        <div className="text-center my-4">
          <span className="spinner"></span> Loading...
        </div>
      )}
      <table
        className={`table-auto w-full border-collapse border border-gray-200 transition-opacity duration-300 ${
          isTransitioning ? "opacity-0" : "opacity-100"
        }`}
      >
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Select</th>
            <th className="border p-2">Index</th>
            <th className="border p-2">Gateway Serial Number</th>
            <th className="border p-2">Gateway MAC Address</th>
            <th className="border p-2">Whitelisted Date</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((row, index) => (
            <tr key={row.guid}>
              <td className="border p-2">
                <input
                  type="checkbox"
                  checked={selectedRows.includes(row.guid)}
                  onChange={() => handleRowSelect(row.guid)}
                />
              </td>
              <td className="border p-2">
                {index + 1 + (currentPage - 1) * rowsPerPage}
              </td>
              <td className="border p-2">
                {editRowId === row.guid ? (
                  <input
                    type="text"
                    name="serialNumber"
                    value={editValues.serialNumber}
                    className="border p-1 w-full"
                    onChange={handleEditChange}
                  />
                ) : (
                  row.serialNumber
                )}
              </td>
              <td className="border p-2">
                {editRowId === row.guid ? (
                  <input
                    type="text"
                    name="macAddress"
                    value={editValues.macAddress}
                    className="border p-1 w-full"
                    onChange={handleEditChange}
                  />
                ) : (
                  row.macAddress
                )}
              </td>
              <td className="border p-2">{new Date(row.whitelistedDate).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                        .toUpperCase()}</td>
              <td className="border p-2 flex space-x-2">
                {editRowId === row.guid ? (
                  <>
                    {/* Show Save button in edit mode */}
                    <button
                      className="text-green-500"
                      onClick={() => handleSaveClick(row.guid)}
                    >
                      Save
                    </button>
                    <button
                      className="text-red-500"
                      onClick={() => setEditRowId(null)} // Cancel edit mode
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    {/* Show Edit and Delete buttons when not in edit mode */}
                    <button
                      className="text-blue-500"
                      onClick={() =>
                        handleEditClick(
                          row.guid,
                          row.serialNumber,
                          row.macAddress
                        )
                      }
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="text-red-500"
                      onClick={() => handleDeleteClick(row.guid)}
                    >
                      <FaTrash />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        {renderPaginationButtons()}
      </div>
    </div>
  );
}

export default WhiteListTable;
